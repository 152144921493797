function Managements () {
  const couriersFile = document.getElementById('couriers_file')
  const submitBtn = document.getElementById('import_couriers_submit_btn')
  const importForm = document.getElementById('bulk_import_form')

  if (importForm) {
    couriersFile.addEventListener('input', function () {
      if ((this.files[0].length !== 0)) {
        submitBtn.removeAttribute('disabled')
      } else {
        submitBtn.setAttribute('disabled', true)
      }
    })

    importForm.addEventListener('submit', function () {
      submitBtn.setAttribute('disabled', true)
    })
  }
}

export default Managements
